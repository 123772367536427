.loginmainBox {
    display: flex;
    min-height: 100vh;
    width: 100%;
    max-width: 1300px;
    margin: auto;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.LoginCardBox {
    width: calc(100% - 480px);
    margin-right: 60px;
}

.LoginTopImg {
    width: 360px;
    position: absolute;
    top: 0;
    left: 0;
}

.loginMobileLogo {
    display: none;
    width: 200px;
    margin-bottom: 20px;
}

/* ************************** */
@media only screen and (max-width: 1550px) {
    .loginmainBox {
        max-width: 1300px;
    }
}


/* ************************** */
@media only screen and (max-width: 1480px) {
    .loginmainBox {
        max-width: 1150px;
    }

    .LoginTopImg {
        width: 320px;
    }
}
/* ************************** */
@media only screen and (max-width: 1380px) {


    .LoginTopImg {
        width: 290px;
    }
}
/* ************************** */
@media only screen and (max-width: 1280px) {
    .loginmainBox {
        max-width: 1100px;
    }

    .LoginTopImg {
        width: 280px;
    }
}

/* ************************** */
@media only screen and (max-width: 1140px) {
    .loginmainBox {
        max-width: 1000px;
    }

    .LoginTopImg {
        width: 250px;
    }
}

/* ************************** */
@media only screen and (max-width: 1040px) {
    .loginmainBox {
        max-width: 96%;
    }
}

/* ************************** */

@media only screen and (max-width: 900px) {
    .loginMobileLogo {
        display: block;
    }
}

/* ************************** */
@media only screen and (max-width: 640px) {
    .LoginTopImg {
        display: none;
    }


}